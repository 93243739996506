const Pen = () => {
  return(
    <svg version="1.0" xmlns="http://www.w3.org/2000/svg"
 width="80%" height="80%" viewBox="0 0 1600.000000 1407.000000"
>
<g transform="translate(0.000000,1407.000000) scale(0.100000,-0.100000)"
fill="white" stroke="none">
<path d="M14305 13337 c0 -243 -13 -314 -86 -464 -56 -117 -125 -187 -289
-293 -392 -256 -348 -232 -545 -300 -178 -62 -236 -91 -281 -144 -46 -53 -69
-120 -89 -254 -25 -174 -59 -266 -114 -316 -26 -23 -26 -23 -1 50 12 32 45
156 75 274 29 118 57 224 61 235 5 13 -11 3 -44 -27 -65 -58 -121 -95 -292
-192 -74 -42 -187 -117 -250 -167 -244 -193 -325 -236 -540 -292 -138 -35
-171 -40 -308 -45 -180 -5 -146 26 -252 -237 -40 -99 -91 -216 -113 -260 -40
-82 -122 -215 -132 -215 -3 0 13 36 35 79 50 101 109 271 165 482 25 91 45
169 45 174 0 5 -14 -12 -31 -38 -75 -114 -212 -221 -371 -289 -169 -73 -363
-118 -713 -168 -121 -17 -294 -44 -385 -60 -220 -39 -333 -54 -681 -90 -549
-57 -791 -95 -1038 -166 -401 -114 -754 -325 -1075 -642 -300 -296 -447 -524
-671 -1042 -53 -124 -126 -281 -161 -350 -67 -134 -185 -321 -191 -304 -6 18
106 449 153 590 77 230 179 438 316 642 27 39 126 174 220 300 174 230 289
398 348 507 38 69 35 67 -47 -35 -122 -152 -259 -264 -618 -503 -367 -245
-497 -347 -633 -500 -169 -190 -432 -648 -727 -1266 l-140 -293 -3 104 c-4
134 18 279 75 498 80 310 133 524 130 526 -5 5 -390 -740 -446 -863 -224 -493
-279 -790 -288 -1548 l-6 -440 -29 61 c-53 114 -62 182 -61 456 1 140 -2 250
-7 253 -12 7 -36 -76 -56 -190 -24 -134 -14 -390 25 -675 18 -124 30 -236 29
-250 -2 -14 -22 47 -44 135 -23 88 -56 190 -73 226 -106 224 -316 284 -477
136 -30 -27 -60 -59 -66 -71 -7 -13 4 -8 28 16 99 94 201 73 331 -66 195 -209
270 -509 217 -863 -13 -92 -59 -291 -69 -301 -1 -2 -21 2 -44 8 -84 22 -122
80 -141 212 -27 192 -34 226 -51 260 -21 42 -49 63 -94 74 l-30 7 27 -29 c42
-46 68 -116 79 -217 17 -162 30 -222 55 -266 13 -24 22 -46 19 -49 -12 -12
-229 19 -302 43 -210 71 -340 214 -351 390 -4 66 -2 81 23 130 84 173 242 118
208 -72 -5 -29 -7 -53 -4 -53 9 1 66 79 84 115 24 47 35 128 26 185 -30 191
-257 300 -392 189 -49 -41 -93 -136 -107 -233 -26 -175 46 -499 162 -731 116
-231 250 -357 397 -372 l56 -6 -45 -19 c-62 -26 -156 -48 -210 -48 -161 0
-312 107 -397 281 -65 131 -78 190 -78 361 0 142 -1 147 -17 125 -53 -70 -119
-288 -138 -455 -29 -265 52 -452 240 -551 110 -58 143 -64 430 -70 l265 -6
-40 -24 c-148 -87 -322 -110 -655 -86 -118 8 -228 15 -245 14 -38 0 277 -73
415 -95 74 -12 137 -15 215 -11 147 7 233 37 343 119 30 22 55 38 56 36 2 -1
-23 -57 -55 -123 -138 -283 -272 -605 -442 -1060 -101 -269 -177 -494 -177
-522 0 -9 -17 -13 -60 -13 -37 0 -60 -4 -60 -11 0 -7 -17 -9 -52 -5 -184 21
-553 12 -627 -16 -15 -5 -28 -5 -36 2 -9 8 -38 8 -97 1 -45 -6 -137 -15 -203
-21 -310 -27 -589 -66 -608 -85 -7 -6 -56 -16 -110 -22 -88 -11 -104 -16 -160
-52 -34 -23 -66 -44 -72 -49 -5 -4 -33 -24 -62 -45 -76 -56 -148 -139 -167
-193 -58 -171 39 -312 299 -440 169 -82 341 -138 713 -230 231 -57 316 -75
360 -73 32 2 116 -9 192 -24 74 -15 188 -32 253 -38 93 -9 115 -14 107 -24
-24 -29 78 -34 610 -31 343 2 676 -2 959 -12 448 -16 435 -17 689 20 66 9 108
9 205 -2 67 -7 212 -14 322 -15 110 -2 218 -8 240 -14 43 -11 232 -13 439 -2
107 5 131 9 140 23 16 27 -27 64 -109 94 -223 82 -226 83 -375 94 -80 6 -208
15 -285 21 -77 6 -201 19 -275 29 -180 25 -350 38 -615 46 -211 6 -364 25
-438 53 -22 8 -27 16 -22 30 3 11 1 22 -5 26 -21 13 -9 27 42 50 73 33 414 68
668 69 155 1 203 -3 295 -21 173 -36 502 -53 665 -36 101 10 48 21 -140 28
-96 3 -331 20 -522 36 l-347 31 -173 -21 c-95 -12 -231 -26 -303 -31 -71 -5
-197 -18 -280 -29 -82 -11 -166 -22 -185 -24 l-35 -3 40 -8 c22 -5 49 -16 60
-26 71 -62 220 -115 367 -130 57 -6 328 -28 603 -50 680 -55 738 -62 867 -104
126 -41 208 -75 208 -87 0 -25 -287 -31 -550 -10 -124 10 -249 12 -430 7 -748
-19 -800 -19 -975 -1 -141 14 -224 16 -485 10 -289 -6 -490 0 -637 21 l-42 6
40 51 c55 69 122 184 222 383 81 159 92 176 189 280 74 80 123 144 173 229 39
65 98 148 132 185 l62 67 83 5 c49 3 84 10 86 16 5 17 174 15 269 -3 147 -28
379 -48 703 -59 84 -3 84 -3 15 4 -38 3 -65 8 -60 10 6 2 -10 6 -35 10 -35 5
-23 7 56 8 65 1 118 -3 150 -13 27 -8 60 -15 74 -15 20 0 22 -2 10 -10 -11 -7
6 -10 55 -11 113 -1 197 -8 209 -18 18 -15 186 -25 186 -11 0 15 107 5 220
-22 47 -11 126 -21 175 -23 50 -2 112 -8 138 -14 30 -7 99 -9 178 -6 95 5 134
3 145 -6 10 -8 56 -12 142 -12 76 1 119 -2 107 -7 l-20 -9 20 -1 c11 0 85 -7
165 -14 80 -7 183 -11 230 -9 47 3 103 0 125 -6 37 -10 36 -10 -22 -6 -35 3
-63 1 -63 -4 0 -12 169 -23 243 -15 82 8 305 -5 286 -17 -9 -5 11 -9 53 -9 37
0 97 -6 135 -14 50 -10 201 -16 563 -20 272 -4 513 -11 535 -16 22 -5 51 -10
64 -10 20 0 21 2 11 15 -11 13 -1 15 77 15 50 0 94 -5 100 -11 7 -7 48 -8 134
-1 126 10 200 6 210 -9 3 -5 88 -4 195 2 104 6 215 10 248 10 32 -1 90 5 130
13 43 9 161 17 301 21 272 8 306 10 365 24 25 6 68 10 95 9 l50 -1 -40 -8
c-43 -8 100 -3 190 7 37 4 46 3 35 -5 -11 -8 6 -10 65 -10 93 1 161 16 85 19
-34 1 -40 3 -25 9 27 11 340 32 360 25 8 -4 54 4 102 16 70 18 103 21 172 16
50 -3 78 -2 66 3 l-20 8 20 6 c11 3 63 11 115 17 52 6 151 22 220 36 69 15
143 27 165 29 52 4 116 19 189 45 33 12 100 26 150 31 50 5 127 17 171 26 44
8 98 17 120 19 22 1 108 25 192 52 83 28 170 53 192 57 23 4 41 10 41 15 0 5
32 21 70 36 40 16 66 30 59 35 -13 8 103 46 146 47 28 1 29 1 11 -13 -11 -8
-29 -16 -40 -16 -12 -1 -28 -2 -36 -3 -20 -1 -90 -45 -90 -57 0 -6 7 -6 18 0
23 12 92 30 117 29 11 0 74 14 140 31 130 34 295 110 520 239 88 51 123 67
133 59 10 -8 -22 -29 -125 -83 -76 -41 -131 -74 -123 -74 8 0 31 10 50 22 19
11 88 48 153 80 64 32 117 63 117 67 0 9 150 136 161 136 4 0 11 4 16 9 5 5 3
6 -4 2 -7 -4 -13 -3 -13 3 0 10 19 26 32 26 3 0 10 4 15 9 5 5 2 6 -6 1 -9 -5
-12 -3 -9 6 3 8 10 13 16 12 7 -2 9 4 5 14 -4 11 -2 14 5 9 16 -10 43 85 49
174 12 178 -52 317 -226 490 -58 58 -127 119 -154 135 -26 17 -45 35 -43 41 5
12 -93 56 -231 104 -88 31 -93 34 -93 61 0 32 6 34 44 19 64 -24 -8 23 -90 59
-47 21 -47 21 -29 1 28 -31 12 -24 -94 39 -67 41 -105 58 -119 54 -30 -7 -92
15 -86 32 4 10 -16 18 -73 31 -48 10 -83 24 -90 35 -27 37 -254 97 -438 114
-106 10 -164 25 -159 40 12 31 -37 39 -226 37 -102 -1 -212 3 -245 8 -33 6
-80 15 -105 19 l-45 8 40 3 c22 2 117 3 210 2 157 -1 167 0 130 13 -22 7 -48
14 -58 14 -10 1 -16 5 -13 10 9 14 -151 41 -269 45 l-110 5 105 -14 c58 -8
117 -14 133 -14 15 -1 27 -6 27 -12 0 -13 -136 -7 -285 12 -104 13 -117 18
-85 32 23 10 19 11 -35 16 -33 4 -65 9 -71 13 -7 5 -74 12 -150 18 -77 5 -176
19 -222 30 -46 11 -86 20 -89 20 -3 0 9 -13 26 -29 35 -32 38 -43 17 -61 -21
-17 -169 7 -162 26 3 8 1 14 -3 14 -5 0 -11 -5 -13 -11 -3 -9 -22 -8 -74 0
-93 16 -111 28 -56 37 54 9 54 23 0 23 -24 0 -124 4 -223 9 -121 6 -190 5
-210 -2 -53 -18 -301 -11 -770 24 -75 5 -124 5 -132 -1 -18 -15 -359 -58 -458
-58 -98 1 -182 -11 -308 -41 -45 -11 -127 -23 -182 -26 -94 -6 -99 -5 -84 11
12 12 48 20 138 29 66 7 124 16 128 19 10 11 -9 9 -213 -18 -262 -35 -484 -71
-514 -84 -22 -10 -21 -11 15 -6 l40 5 -50 -20 c-81 -33 -345 -102 -366 -97
-22 6 -121 -17 -148 -34 -12 -8 -10 -9 12 -4 15 3 27 4 27 2 0 -2 -55 -26
-122 -53 -66 -27 -113 -50 -104 -52 9 -2 22 1 29 7 21 16 246 87 337 105 149
29 230 50 220 56 -12 8 31 21 150 48 101 23 91 22 210 27 129 5 284 21 470 50
207 33 585 62 740 58 69 -2 195 -5 280 -6 157 -3 592 -31 620 -41 26 -9 437
-29 835 -41 134 -4 327 -29 465 -61 36 -8 167 -30 291 -49 220 -33 330 -55
474 -94 39 -11 96 -25 128 -31 31 -6 57 -14 57 -17 0 -2 -13 -2 -30 2 -45 10
-36 -1 15 -19 26 -8 43 -10 39 -4 -4 6 1 8 15 3 11 -3 21 -2 21 3 0 14 92 -11
215 -58 56 -22 314 -100 328 -100 16 0 191 -81 207 -96 9 -7 46 -23 83 -34 45
-14 78 -32 101 -55 19 -18 53 -43 77 -56 68 -34 184 -103 187 -111 2 -4 31
-27 65 -50 65 -46 190 -189 246 -282 91 -152 110 -274 58 -374 -36 -69 -86
-123 -166 -180 -37 -26 -68 -50 -69 -54 -4 -9 -171 -92 -187 -93 -9 -1 -146
-53 -285 -108 -30 -12 -62 -23 -70 -24 -94 -11 -167 -39 -203 -76 -13 -14 -45
-28 -81 -36 -33 -7 -80 -22 -105 -33 -25 -10 -54 -17 -65 -14 -18 5 -213 -42
-356 -85 -36 -11 -129 -33 -206 -50 -78 -16 -157 -35 -176 -43 -19 -8 -104
-24 -189 -36 -85 -12 -189 -28 -233 -36 -43 -7 -94 -14 -114 -14 -19 0 -40 -7
-46 -15 -8 -11 -46 -18 -151 -25 -77 -6 -168 -15 -202 -22 -47 -9 -93 -9 -193
0 -123 11 -133 11 -168 -8 -40 -22 -72 -26 -72 -10 0 16 -54 12 -237 -15 -92
-14 -191 -25 -218 -25 -28 0 -56 -5 -61 -10 -11 -11 -92 -16 -364 -25 -85 -2
-202 -9 -260 -15 -58 -6 -220 -13 -360 -17 -293 -7 -301 -7 -670 -5 -151 1
-286 2 -300 2 -226 -1 -622 11 -790 23 -118 9 -282 19 -365 22 -82 3 -242 15
-355 25 -230 21 -295 24 -460 20 -68 -1 -105 -5 -90 -9 24 -7 24 -8 -8 -15
-41 -10 -170 4 -132 14 23 6 23 7 -10 13 -19 3 -66 9 -105 12 -38 3 -92 10
-120 16 -49 9 -809 84 -1160 114 -113 10 -216 13 -277 9 -72 -5 -102 -4 -113
6 -9 7 -38 11 -77 10 -35 -2 -76 -2 -93 0 -29 2 -29 2 -5 9 14 4 -31 5 -99 3
-70 -3 -146 0 -175 6 -65 14 -269 24 -241 12 23 -10 -117 -11 -298 -1 l-117 6
36 50 c90 124 136 273 154 495 12 150 33 251 80 385 27 75 227 546 289 680
l18 40 35 -35 c51 -51 58 -97 68 -481 10 -352 17 -413 64 -527 24 -61 91 -167
120 -191 20 -17 20 -13 0 34 -42 101 -54 276 -33 476 8 76 7 159 -1 308 -12
207 -7 341 13 341 6 0 34 -24 63 -54 55 -56 93 -120 114 -194 7 -23 15 -40 17
-38 3 2 -1 56 -7 119 -7 66 -8 122 -3 130 6 13 25 0 98 -65 232 -208 360 -263
611 -262 77 0 146 4 154 9 8 5 -35 22 -120 47 -257 75 -334 116 -515 274 -66
58 -132 113 -147 122 -16 9 -28 19 -28 23 0 16 89 44 140 44 47 -1 76 -10 185
-62 72 -34 145 -66 164 -72 36 -12 207 -13 256 -2 28 6 27 7 -18 14 -65 9
-129 38 -286 129 -147 85 -224 115 -316 125 -116 13 -11 25 285 33 233 7 300
12 365 29 105 26 238 73 342 122 85 39 269 144 278 159 3 4 -27 -5 -65 -21
-151 -64 -211 -71 -580 -75 -205 -1 -319 1 -295 6 22 5 92 19 155 31 224 43
505 147 813 300 129 65 677 358 677 363 0 1 -30 -1 -67 -5 -38 -4 -115 -3
-173 2 -258 22 -349 22 -550 0 -107 -12 -233 -21 -280 -19 l-85 3 45 29 c111
72 259 110 490 126 85 6 216 15 291 20 154 10 292 37 459 87 273 82 478 179
842 399 133 80 317 183 408 227 153 75 171 86 240 157 78 78 160 192 249 343
119 204 186 363 330 788 177 526 265 729 401 933 81 120 228 280 317 344 243
174 723 507 888 617 334 221 598 364 864 468 l115 45 -35 22 c-74 46 -244 124
-341 156 -57 18 -98 33 -92 34 6 0 82 -12 170 -26 210 -34 469 -43 566 -20
190 44 359 146 623 372 200 171 267 223 535 414 261 186 388 289 500 406 253
264 479 658 613 1070 82 250 115 423 124 644 9 224 -19 399 -91 569 -29 70
-115 213 -134 225 -4 2 -7 -91 -7 -207z m-2256 -2421 c-2 -2 -94 -39 -204 -81
-889 -346 -1853 -819 -2700 -1328 -2313 -1389 -4041 -3121 -5130 -5144 -47
-87 -85 -154 -85 -150 0 37 215 483 343 712 791 1413 2046 2765 3582 3856
1129 803 2449 1493 3855 2017 118 44 240 89 270 100 52 20 78 27 69 18z
m-4206 -2468 c-1301 -1256 -2346 -2526 -3065 -3728 -367 -613 -650 -1205 -788
-1650 -49 -160 -61 -185 -152 -313 -154 -217 -271 -293 -324 -213 -14 23 -13
35 26 183 248 953 850 2049 1724 3138 234 292 551 662 750 875 34 35 99 105
146 156 152 163 760 765 950 939 338 309 885 782 908 785 2 0 -76 -77 -175
-172z m-4133 -6113 c0 -2 -42 -32 -92 -67 -119 -79 -183 -140 -273 -258 -40
-52 -100 -125 -133 -163 -66 -74 -99 -146 -187 -400 -26 -75 -50 -136 -51
-134 -6 6 112 348 144 419 16 35 50 88 76 117 26 30 90 106 141 170 115 144
135 163 265 250 114 75 110 73 110 66z m-544 -127 c9 -10 -123 -486 -191 -688
-87 -260 -121 -381 -131 -463 -5 -46 -10 -57 -25 -57 -10 0 -20 4 -23 9 -10
16 -167 73 -224 81 -31 4 -106 19 -167 33 -60 14 -173 35 -250 47 -182 28
-507 110 -665 167 -69 25 -173 69 -233 99 -59 29 -111 54 -115 54 -4 0 -20 10
-36 22 -16 13 -40 30 -53 38 -44 27 -85 95 -91 148 -6 64 19 111 95 179 111
100 154 129 179 119 7 -3 30 2 50 10 130 55 391 100 859 149 132 13 263 30
290 36 91 20 713 34 731 17z"/>
<path d="M7310 1479 c-109 -12 -302 -22 -572 -28 -365 -9 -563 -22 -578 -38
-3 -3 265 -2 595 3 330 5 688 9 795 9 192 0 196 0 303 -33 75 -23 143 -36 220
-42 196 -16 539 -86 598 -122 29 -18 30 -20 16 -41 -8 -12 -28 -30 -46 -40
-28 -17 -32 -17 -47 -3 -8 9 -28 16 -44 16 -16 0 -37 9 -47 19 -15 16 -27 18
-65 14 -27 -3 -48 -10 -48 -14 0 -5 -12 -9 -27 -9 -16 0 -34 -4 -42 -9 -23
-15 42 -48 122 -62 119 -22 271 18 320 83 28 37 17 55 -55 88 -102 47 -226 78
-446 110 -114 17 -265 44 -337 61 -73 17 -183 34 -250 39 -66 5 -138 11 -160
14 -22 2 -114 -4 -205 -15z"/>
<path d="M7330 1264 c-19 -3 -93 -8 -165 -13 l-130 -7 38 -17 c61 -28 194 -56
317 -68 259 -23 520 -68 520 -89 0 -9 -27 -16 -97 -26 -66 -8 -92 -26 -77 -51
25 -41 60 -47 285 -51 259 -5 429 16 429 54 0 8 -31 34 -68 58 -92 59 -136
116 -99 129 26 10 -48 27 -181 42 -75 9 -168 21 -207 26 -74 10 -506 20 -565
13z"/>
<path d="M8655 1079 c-113 -15 -167 -39 -134 -60 8 -5 93 -9 189 -9 151 0 181
3 218 20 43 19 55 43 26 54 -22 9 -216 5 -299 -5z"/>
<path d="M3065 809 c-22 -4 -62 -8 -90 -8 -88 -2 -374 -48 -401 -66 -32 -21
-28 -45 11 -65 54 -27 617 -14 735 16 30 8 121 19 203 24 197 12 269 32 217
60 -12 6 -116 16 -233 21 -117 5 -237 14 -267 19 -67 10 -122 10 -175 -1z"/>
<path d="M4465 775 c-96 -18 -145 -33 -145 -45 0 -35 234 -46 332 -17 31 10
60 22 64 28 24 40 -115 59 -251 34z"/>
</g>
</svg>
  )
}
export default Pen;