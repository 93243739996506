import styled from 'styled-components'


export const BlogContainer = styled.div`
  margin: 0 auto;
  padding: 10px;
  display: flex;
  background: black;
  color: black;
  flex: wrap;
`